import React from 'react'
import CookieConsent,{ Cookies } from 'react-cookie-consent'
import { useLocation } from '@reach/router'
import { initializeAndTrack} from 'gatsby-plugin-gdpr-cookies'

// CookieConsent and cookie related stuff from https://www.improvebadcode.com/gatsby-gdpr-cookie-consent/

import Header from '../components/header'
import Footer from '../components/footer'
import '../styles/index.scss'
import * as layoutStyles from './layout.module.scss'

// paramter props je vlastne obsah tagu <Layout />, to co je nim obklopene.
const Layout = (props) => {
	const location = useLocation()

	return (
		<div className={layoutStyles.container}>
			<div className={layoutStyles.content}>
			<Header />
			{props.children}
			</div>
			<Footer />
			<CookieConsent
				location="bottom"
				enableDeclineButton="true"
				buttonText="Agree"
				declineButtonText="Decline"
				cookieName="mo-web-google-analytics"
				onAccept={
					() => {
						Cookies.set("mo-web-google-analytics", true)
						initializeAndTrack(location)
					}
				}>
			This site uses cookies to enhance the user experience.
			</CookieConsent>
		</div>
	)
}

export default Layout