import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

// import './header.module.scss'
// toto je default export header.module.scss
import * as headerStyles from './header.module.scss'

const Header = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
			allContentfulMainPage {
				edges {
				  node {
					title
					slug
				  }
				}
			  }
		}
	`)

	const menuItems = data.allContentfulMainPage.edges.map((item) => 
	<li>
		<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to={`/${item.node.slug}`}>
			{item.node.title}
		</Link>
	</li>
	)
	menuItems.unshift(
		<li>
		<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link>
		</li>
	)
	menuItems.unshift(
		<li>
		<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link>
	</li>

	)


	return (
		<header className={headerStyles.header}>
			<h1>
				<Link to="/" className={headerStyles.title}>
					{data.site.siteMetadata.title}
				</Link>
			</h1>
			<nav>
				<ul className={headerStyles.navList}>
					{/* <li>
						<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/">Home</Link>
					</li>
					<li>
						<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link>
					</li>
					<li>
						<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/contact">Contact</Link>
					</li>
					<li>
						<Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/about">{data.allContentfulMainPage.edges[0].node.title}</Link>
					</li> */}
					{menuItems}
				</ul>
			</nav>
		</header>
	)
}

export default Header